<template>
    <div class="text-center">
        <template>
        <button
            @click="loginAs()"
            class="btn btn-sm btn-clean btn-icon btn-icon-md"
            title="login as"
        >
            <i class="fas fa-sign-in-alt"></i>
        </button>
        </template>
        <template>
        <button
            @click="changePassword()"
            class="btn btn-sm btn-clean btn-icon btn-icon-md"
            title="Change Password"
        >
            <i class="la la-lock"></i>
        </button>
        </template>
        <template>
        <button
            @click="editUser()"
            class="btn btn-sm btn-clean btn-icon btn-icon-md"
            title="Edit"
        >
            <i class="la la-pencil-square"></i>
        </button>
        </template>
        <!-- <template>
        <button
            @click="deleteUser()"
            class="btn btn-sm btn-clean btn-icon btn-icon-md"
            title="Hapus"
        >
            <i class="la la-trash"></i>
        </button>
        </template> -->
        <template>
        <button
            @click="copyUser()"
            class="btn btn-sm btn-clean btn-icon btn-icon-md"
            title="Copy User"
        >
            <i class="la la-copy"></i>
        </button>
        </template>
    </div>
</template>

<script>
import CheckPermission from "../../../services/checkPermission.service";
import { mapState, mapMutations, mapActions } from 'vuex';
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
const UserRepository = RepositoryFactory.get("user");
const checkPermission = new CheckPermission();
const SweetAlert = Vue.extend(SweetAlertTemplate);
import User from "../../../model/user-model";
export default {
     data() {
        return {
            showSpinner: false
        }
    },
    props: {
        userVue: { type: Object },
        rowData: { type: Object }
    },
    methods: {
        async loginAs(){
            //get token and send data
            let obj ={
                userId: this.rowData.id
            }
            let {data} = await UserRepository.loginImpersonate(obj)
            localStorage.setItem("impersonate", JSON.stringify(this.rowData));
            // location.reload();
            // console.log(window.access_token , 'ini sebelum');
            localStorage.removeItem('permission')
            window.access_token = data.access_token
            window.refresh_token = data.refresh_token
            window.user_name = this.rowData.firstName
            // console.log(auth_token);
            // console.log(access_token);
            localStorage.setItem('new_token', data.access_token)
            localStorage.setItem('new_refresh', data.refresh_token)
                this.$router.go('/dashboard');

          
            // new Promise((resolve, reject) => {
            //     checkPermission.updatePermission(resolve, reject);
            // })
            //     .then(response => {
            //         console.log('jalan');
            //         // console.log(response);
            //         localStorage.setItem('permission', response)
            //         //   vx.initialMenu();
            //         //   vx.localStorageReady = true;
            //         //   vx.initChat();
            //     })
            //     .catch(error => {
            //         return error;
            //     });
        },
        deleteUser() {
            var vx = this;
            let instance = new SweetAlert().showConfirm({
                swalTitle: "Apakah Anda yakin?",
                swalText: "Data User akan terhapus.",
                swalType: "warning",
                onConfirmButton: function () {
                    blockUI.blockPage();
                    vx.delete();
                },
                onCancelButton: function () {
                    blockUI.unblockPage();
                }
            });
        },
        async delete() {
            var vx = this;

            await UserRepository.deleteUser(this.rowData.login)
                .then(response => {
                    new Promise((resolve, reject) => {
                        vx.userVue.fetch(resolve);
                    }).then((response) => {
                        let instance = new SweetAlert().showInfo({
                        swalTitle: "Berhasil!",
                        swalText: "Data User berhasil terhapus.",
                        swalType: "success",
                        onConfirmButton: function () {
                            blockUI.unblockPage();
                        }
                        });
                    });
                })
                .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: "Data User gagal terhapus.",
                        swalType: "error",
                        onConfirmButton: function () {
                            blockUI.unblockPage();
                        }
                    });
                });
            },
        editUser() {
            blockUI.blockPage();
            UserRepository.getUser(this.rowData.login).then(res => {
                blockUI.unblockPage();
                this.userVue.dataEdit = res.data;
                this.userVue.showModal();
            }).catch(error => {
                blockUI.unblockPage();
                var msg = (error.message) ? error.message : error;
                this.showToastError('Get User : ' + msg);
            })
        },
        copyUser() {
            blockUI.blockPage();
            UserRepository.getUser(this.rowData.login).then(res => {
                blockUI.unblockPage();
                let user = new User();
                user.authorities = res.data.authorities;
                user.userGroups = res.data.userGroups;
                this.userVue.dataEdit = user;
                this.userVue.showModal();
            }).catch(error => {
                blockUI.unblockPage();
                var msg = (error.message) ? error.message : error;
                this.showToastError('Get User : ' + msg);
            })
        },
        changePassword(){
            blockUI.blockPage();
            UserRepository.getUser(this.rowData.login).then(res => {
                blockUI.unblockPage();
                this.userVue.dataEdit = res.data;
                this.userVue.showModalPassword();
            }).catch(error => {
                blockUI.unblockPage();
                var msg = (error.message) ? error.message : error;
                this.showToastError('Get User : ' + msg);
            })
            // this.userVue.dataEdit = this.rowData;
            // this.userVue.showModalPassword();
        },
        checkPermission: function (method) {
            return checkPermission.isCanAccess(method);
        },
        showToastError: function(message) {
            toastr.error(message);
        },
    },
    beforeRouteUpdate (to, from, next) {
    // this.checkPermission(example.example)
    // next()
  },
}
</script>

<style>

</style>
