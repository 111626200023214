<template>
  <div>
    <base-header :base-title="headTitle"></base-header>
    <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__head kt-portlet__head--lg">
          <base-title :head-title="headTitle"></base-title>
          <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-wrapper">
              <router-link
                class="btn btn-default btn-bold btn-upper btn-font-sm mr-2"
                :to="{
                  name: 'entity-data-version',
                  query: { type: $router.currentRoute.query.type },
                }"
              >
                <i class="flaticon2-back"></i>
                {{ $t("button.previous") }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="kt-portlet__body">
          <div class="row mb-2">
            <div class="col">
              <b-form-checkbox v-model="isSeeAll" name="seeAll">
                Lihat Semua Data
              </b-form-checkbox>
            </div>
            <div class="col text-center">
              <div class="row">
                <div class="col-sm-6 input-group mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text font-weight-bold" for="inputGroupSelect01"
                      >Old Version</label
                    >
                  </div>
                  <select
                    class="custom-select"
                    id="inputGroupSelect01"
                    v-model="version.old"
                    @change="dataOld"
                  >
                    <option v-for="old in version.new - 1" :key="`old-${old}`">
                      {{ old }}
                    </option>
                  </select>
                </div>
                <div class="col-sm-6 input-group mb-3">
                  <select
                    class="custom-select"
                    id="inputGroupSelect02"
                    v-model="version.new"
                    @change="dataNew"
                  >
                    <template v-for="newV in version.last">
                      <option :key="`new-${newV}`" v-if="newV > 1">
                        {{ newV }}
                      </option>
                    </template>
                  </select>
                  <div class="input-group-append">
                    <label class="input-group-text font-weight-bold" for="inputGroupSelect02"
                      >New Version</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <template v-if="isDeret">
                <button
                  class="
                    btn btn-default btn-bold btn-upper btn-font-sm
                    float-right
                  "
                  :disabled="rightArrow"
                  @click="btnType('plus')"
                >
                  NEW
                  <!-- <i class="fas fa-arrow-right"></i>  -->
                </button>
                <button
                  class="
                    btn btn-default btn-bold btn-upper btn-font-sm
                    float-right
                  "
                  :disabled="leftArrow"
                  @click="btnType('min')"
                >
                  OLD
                  <!-- <i class="fas fa-arrow-left"></i> -->
                </button>
              </template>
            </div>
          </div>
          <code-diff
            v-if="isLoaded"
            :old-string="oldData"
            :new-string="newData"
            :context="isSeeAll ? 10000000 : 1"
            :diff-style="'word'"
            output-format="side-by-side"
          >
          </code-diff>
          <div v-else class="m-auto p-5 d-flex flex-row h-100">
            <div
              class="
                kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary
                my-auto
              "
            ></div>
            <h5 class="my-auto pl-5">Loading ...</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const json = require("json-keys-sort");
import BaseHeader from "./../_base/BaseHeader";
import BaseTitle from "./../_base/BaseTitle";
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
const EntityDataRepository = RepositoryFactory.get("entityVersionRepository");

export default {
  components: {
    BaseHeader,
    BaseTitle,
  },
  computed: {
    isDeret() {
      if (this.version.new - 1 == this.version.old) {
        return true;
      }
      return false;
    },
    leftArrow() {
      if (this.version.old > 1) {
        return false;
      }
      return true;
    },
    rightArrow() {
      if (this.version.new == this.version.last) {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      versionActive: "",
      isSeeAll: false,
      version: {
        old: 0,
        new: 1,
        last: 1,
      },
      headTitle: "Entity Version",
      isLoaded: false,
      oldData: [],
      newData: [],
      paramsData: {
        type: "",
        entityId: "",
      },
    };
  },
  mounted() {
    const router = this.$router.currentRoute;
    const type = router.query.type;
    const entityId = router.params.id;
    const version = router.params.version;
    this.paramsData = { type, entityId };
    this.versionActive = version;
    this.version.old = Number(version) - 1;
    this.version.new = Number(version);
    this.version.last = Number(version);
    this.getDataVersion({ type, entityId });
  },
  methods: {
    btnType(type) {
      this.isLoaded = false;
      if (type == "min") {
        this.versionActive = this.versionActive - 1;
        this.version.old--;
        this.version.new--;
      } else {
        this.versionActive = this.versionActive + 1;
        this.version.old++;
        this.version.new++;
      }
      this.getDataVersion(this.paramsData);
    },
    async getDataVersion(router) {
      const routerData = {};
      routerData.qualifiedName = router.type;
      routerData.entityId = router.entityId;
      routerData.commitVersion = Number(this.version.old) + 1;

      // check mengenai entity prev
      if (this.version.old > 0) {
        let responseOld = await EntityDataRepository.previousAudit(routerData);
        if (responseOld.status == 200) {
          const data = responseOld.data;
          data.entityValue = JSON.parse(data.entityValue);
          const oldData = json.sort(data, true);
          const strOldData = JSON.stringify(oldData, null, "\t");
          this.oldData = strOldData;
        }
      } else {
        this.oldData = "";
      }
    // check mengenai entity new
      routerData.commitVersion = Number(this.version.new) + 1;
      let responseNew = await EntityDataRepository.previousAudit(routerData);
      if (responseNew.status == 200) {
        const data = responseNew.data;
        data.entityValue = JSON.parse(data.entityValue);
        const newData = json.sort(data, true);
        const strNewData = JSON.stringify(newData, null, "\t");
        this.newData = strNewData;
      }
      setTimeout(() => {
        this.isLoaded = true;
      }, 2000);
    },
    dataNew() {
      this.isLoaded = false;
      if (this.version.new <= this.version.old) {
        this.version.old = this.version.new - 1;
      }
      this.getDataVersion(this.paramsData);
    },
    dataOld() {
      this.isLoaded = false;
      this.getDataVersion(this.paramsData);
    },
  },
};
</script>

<style>
</style>