<template>
    <div>
        <base-header base-title="User Management"></base-header>
        <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title :head-title="headTitle"></base-title>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <auto-refresh 
                            ref="auto"
                            :table-name="tableName" :on-refresh="fetch"
                            :auto-refresh-state="false"
                            ></auto-refresh>&nbsp;&nbsp;
                            <template>
                            <base-button-modal
                                :button-text="modalText"
                                :button-icon="modalIcon"
                                :button-name="modalName"
                                :onButtonModalClicked="showModal"
                            ></base-button-modal>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="kt-portlet__body" v-if="isLoaded">
                    <!-- <data-tables :table="table"></data-tables> -->
                    <v-server-table  
                        :columns="columns"
                        :options="options" 
                        ref="table"
                        @loaded="onLoaded(0)"
                        @loading="onLoaded(1)"
                    >
                        <div slot="action" slot-scope="{ row }">
                            <btn-action :row-data="row"
                            :user-vue="userVue" ></btn-action>
                        </div>
                        <div v-if="isLoading" slot="afterBody" >
                           <h6 class="txt"> loading..</h6>
                        </div>
                        <div slot="activated" slot-scope="{ row }">
                            <span class="p-2 mt-1 badge badge-success">{{row.activated}}</span> <br/>
                        </div>
                        <div slot="authorities" slot-scope="{ row }">
                            <span class="p-2 mt-1 badge badge-info" v-for="(item, index) in row.authorities" :key="index">{{item}}</span> <br/>
                        </div>
                    </v-server-table>
                </div>
                <div v-else class="m-auto p-5 d-flex flex-row h-100">
                    <div class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"></div>
                    <h5 class="my-auto pl-5">Loading ...</h5>
                </div>
                <form-modal
                    v-if="isShowModal"
                    :show-modal.sync="isShowModal"
                    @event="fetch"
                    :modal-name="modalName"
                    :modal-data="dataEdit"
                ></form-modal>
                <form-password
                    v-if="isShowModalPassword"
                    :show-modal-password.sync="isShowModalPassword"
                    @event="fetch"
                    :modal-name="modalPassName"
                    :modal-data="dataEdit"
                ></form-password>
            </div>
        </div>
    </div>
</template>

<script>
import lang from '../../constants/language'
import CheckPermission from "./../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import BaseTitle from "./../_base/BaseTitle";
import BaseHeader from "./../_base/BaseHeader";
import AutoRefresh from "./../_general/AutoRefresh";
import BaseButtonModal from "./../_base/BaseButtonModal";
import DataTables from "./../_table/DataTables";
import LoadSpinner from "./../_general/LoadSpinner";
import BtnAction from "./_components/BtnAction";
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
const UserRepository = RepositoryFactory.get("user");
export default {
    components: {
        BaseTitle,
        BaseHeader,
        AutoRefresh,
        BaseButtonModal,
        DataTables,
        LoadSpinner,
        BtnAction,
        FormModal: () => ({
        component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                resolve(import(/* webpackChunkName: "modal-input-user" */ "./_components/FormModal.vue"));
                }, 1000);
            }),
            loading: LoadSpinner
        }),
        FormPassword: () => ({
        component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                resolve(import(/* webpackChunkName: "modal-force-password" */ "./_components/FormChangePassword.vue"));
                }, 1000);
            }),
            loading: LoadSpinner
        })
    },
    data() {
        var vx = this;
        return {
            isLoaded: true,
            isShowModalPassword: false,
            isShowModal: false,
            dataEdit: null,
            userVue: vx,
            isLoading: false,
            isFirstReload: true,
            modeSearch: true,
            headTitle: "User Management",
            tableName: "table_user_management",
            modalText: "Tambah User",
            modalName: "modal_user_management",
            modalPassName: "modal_pass_name",
            modalIcon: "flaticon2-add-1",
            columns: ['id','login','email','activated','langKey','authorities', 'createdDate', 'lastModifiedBy', 'action'],
            options: {
                texts: {
                    filterBy: 'Cari',
                },
                filterable: ['login','email','activated','langKey','authorities', 'createdDate', 'lastModifiedBy'],
                headings: {
                    id: "ID",
                    login: "Login",
                    email: "Email",
                    activated: "Activated",
                    langKey: "Bahasa",
                    authorities: "Profile",
                    createdDate: "Tanggal Pembuatan",
                    lastModifiedBy: "Perubahan",
                    lastModifiedDate: "Tanggal Perubahan",
                    action: 'Actions'
                },
                debounce: 500,
                dateColumns: ['createdDate', 'lastModifiedDate' ],
                toMomentFormat: 'YYYY-MM-DD',
                requestFunction(data) {
                    let params = {};
                    let page = {};
                    page.page = data.page-1;
                    page.size = data.limit;
                    this.isLoaded = false
                    if(_.isEmpty(data.query) && localStorage.login){
                        data.query = { 'login': localStorage.login }
                        // setTimes
                    } else {
                        localStorage.removeItem('login')
                    }
                    if(_.isEmpty(data.query)){
                        vx.modeSearch = false;
                        return  UserRepository.fetch(page).catch(function (e) {
                            this.dispatch('error', e);
                        });
                    }else{
                        vx.modeSearch = true;
                        params.query = helpers.filterUserTable(data.query);
                        return UserRepository.search(params.query, page).catch(function (e) {
                            vx.showToastError('Error Get Data');
                            this.dispatch('error', e);
                        });
                    }
                },
                responseAdapter: (resp) => {  
                   
                    return {
                    data: resp.data,
                    count: resp.headers['x-total-count'] ? resp.headers['x-total-count'] : resp.headers['Content-Length'] ? resp.headers['Content-Length'] : resp.data.length
                 }
                },
                rowClassCallback(row) {
                return  (row.isNewData) ? 'highlight':'';
                },
                filterByColumn: true,
                sortable:[],
                templates: {
                    createdDate(h, row) {
                        return moment(row.createdDate).add(7, "hours").format("DD-MMM-YYYY");
                    },
                    authorities(h, row) {
                        let text = '<span class="p-2 mt-1 badge badge-info">'+row.authorities+'</span> <br/>'
                        return row.authorities
                    },
                    activated(h, row) {
                        return row.activated == true ? 'True' : 'False'
                    },
                }
            },
            table: {
                tableName: "table_user_management",
                count: 0,
                tableDataResult: [],
                tableColumnDefs: [
                    {
                        defaultContent: "-",
                        targets: "_all"
                    },
                    {
                        targets: 4,
                        render: function ( data, type, row, meta ) {
                            var text = '-';
                            if(lang.hasOwnProperty(data)) {
                                text = lang[data];
                            }
                            return text;
                        }
                    },
                    {
                        targets: 3,
                        render: function ( data, type, row, meta ) {
                            var text = '<span class="p-2 mt-1 badge badge-danger">Tidak Aktif</span>';
                            if(data) {
                                text = '<span class="p-2 mt-1 badge badge-success">Aktif</span>';
                            }
                            return text;
                        }
                    },
                    {
                        targets: 5,
                        render: function ( data, type, row, meta ) {
                            var text = '';
                            data.forEach(x => {
                                text = text + '<span class="p-2 mt-1 badge badge-info">'+x+'</span> <br/>'
                            });
                            return text;
                        }
                    },
                    {
                        targets: 6,
                        render: function ( data, type, row, meta ) {
                            var momentDate = moment(data);
                            var text = momentDate.format("DD/MM/YYYY") + ' <br/> ' + momentDate.format("hh:mm")
                            return text;
                        }
                    },
                    {
                        targets: 8,
                        render: function ( data, type, row, meta ) {
                            var momentDate = moment(data);
                            var text = momentDate.format("DD/MM/YYYY") + ' <br/> ' + momentDate.format("hh:mm")
                            return text;
                        }
                    },
                    {
                        targets: -1,
                        responsivePriority: 1
                    }
                ],
                tableColumns: [
                {
                    title: "ID",
                    data: "id"
                },
                {
                    title: "Login",
                    data: "login"
                },
                {
                    title: "Email",
                    data: "email"
                },
                {
                    title: "Activated",
                    data: "activated"
                },
                {
                    title: "Bahasa",
                    data: "langKey"
                },
                {
                    title: "Profile",
                    data: "authorities"
                },
                {
                    title: "Tanggal Pembuatan",
                    data: "createdDate"
                },
                {
                    title: "Perubahan",
                    data: "lastModifiedBy"
                },
                {
                    title: "Tanggal Perubahan",
                    data: "lastModifiedDate"
                },
                {
                    title: "Actions",
                    data: "id",
                    createdCell: function(cell, cellData, rowData, rowIndex, colIndex) {
                        let btnAction = Vue.extend(BtnAction);
                        let instance = new btnAction({
                            propsData: {
                                userVue: vx,
                                rowData: rowData
                            }
                    });
                    instance.$mount();
                    $(cell)
                        .empty()
                        .append(instance.$el);
                    }
                }
                ],
                tableFilter: [
                    {
                        title: "Login",
                        type: "text"
                    },
                    {
                        title: "Email",
                        type: "text"
                    },
                    {
                        title: "Activated",
                        type: "text"
                    },
                    {
                        title: "Bahasa",
                        type: "text"
                    },
                    {
                        title: "Profile",
                        type: "text"
                    },
                    {
                        title: "Tanggal Pembuatan",
                        type: "datepicker"
                    },
                    {
                        title: "Perubahan",
                        type: "text"
                    },
                    {
                        title: "Tanggal Perubahan",
                        type: "datepicker"
                    },
                    {
                        title: "Actions",
                        type: "actions"
                    },
                ]
            }
        }
    },
    watch: {
        isShowModal: function(val) {
            if(!val) {
                this.dataEdit = null;
            }
        },
        isShowModalPassword: function(val) {
            if(!val) {
                this.dataEdit = null;
            }
        }
    },
    methods: {
        onLoaded: function(t) {
            t == 0 ? this.isLoading = false : this.isLoading = true  
        },
        showModal: function() {
            this.isShowModal = true;
        },
        showModalPassword: function(){
            this.isShowModalPassword = true;
        },
        fetch: function(resolve) {
            this.$refs.table.getData();
            if(resolve != undefined){
                resolve();
            }
        },
        checkPermission: function (method) {
            return checkPermission.isCanAccess(method);
        },
        showToastError: function(message) {
            toastr.error(message);
        }
    },
    created() {
        // this.fetch();
        // console.log(this.$route.query)
        // if(this.$route.query) {
        //     setTimeout(() => {
        //         let x = document.getElementsByName('vf__login')
        //         x[0].value = this.$route.query.login
        //         console.log(x)
        //     }, 1000)
        // }
    },
    mounted() {
        if(localStorage.getItem('login')){
            let x = document.getElementsByName('vf__login')
            x[0].value = localStorage.getItem('login')
            setTimeout(() => {
                this.fetch()
            }, 500);
            // $('input[name ="vf__login"]').submit()
        }
    }
}
</script>

<style>
</style>
